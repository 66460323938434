var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-1 ma-1", attrs: { tile: "", color: "grey lighten-3" } },
    [
      _c("v-card-title", { attrs: { color: "primary" } }, [
        _vm._v("ジャンルを決定する"),
      ]),
      _c(
        "v-card-text",
        [
          _c("card-button", {
            attrs: {
              headerIsOn: false,
              inputItems: _vm.icons,
              labelIsOn: true,
            },
            on: { "update-query": _vm.chooseGenre },
          }),
          _c("v-divider", { staticClass: "pt-3" }),
          _c("page-transition-button", {
            attrs: { nextIsNecessary: false },
            on: { "click-back": _vm.backToPreviousPage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }